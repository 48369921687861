import { render, staticRenderFns } from "./FooterMessage.vue?vue&type=template&id=5743d838&scoped=true&"
import script from "./FooterMessage.vue?vue&type=script&lang=js&"
export * from "./FooterMessage.vue?vue&type=script&lang=js&"
import style0 from "./FooterMessage.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./FooterMessage.vue?vue&type=style&index=1&id=5743d838&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5743d838",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VDialog,VDivider,VFileInput,VFooter,VIcon,VImg,VListItem,VListItemSubtitle,VSnackbar,VSpacer,VTextarea,VTooltip})
